$(document).ready(function() {
    function displayTabs() {
        $('body').on('click', '[data-tabs-link]', function (e) {
            e.preventDefault();
            var $link = $(this);
            $link.parent().addClass('active').siblings().removeClass('active');

            var $content = $link.closest('[data-tabs]').find('[data-tabs-item]');
            $content.eq($link.parent().index()).addClass('active').siblings().removeClass('active');
        });
    }

    displayTabs();
    $('.js-expert').slick({
        dots: false,
        arrows: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.js-experts',
        draggable: false,
        swipe: false,
        appendArrows: '.js-slider-arrows',
        prevArrow: '<div class="slick-prev slick-arrow"><span class="icon-arrow-left"></span></div>',
        nextArrow: '<div class="slick-next slick-arrow"><span class="icon-arrow-right"></span></div>'
    });

    $('.js-experts').slick({
        dots: false,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.js-expert',
        centerMode: true,
        focusOnSelect: true,
        draggable: false,
        swipe: false,
    });

    function expertPermutation() {

        $('.b-expert').each(function(e) {

            if ($(window).width() < 1024) {
                $(this).find('.b-expert__button').detach().appendTo('.b-expert__wrapper');
            } else {
                $(this).find('.b-expert__button').detach().appendTo('.b-expert__button-group');
            }

        });

    }
    $(window).ready(expertPermutation());
    $(window).resize(function() {
        expertPermutation();
    });
    $('.js-menu-open, .js-menu-close').on('click', function () {
        $('.js-menu-open').toggleClass('active');
        $('.b-header__menu').toggleClass('active');
        return false;
    });

    $(document).on('click', function (event) {
        if ($(event.target).closest(".js-menu-open, .b-header__list").length) return;
        $('.js-menu-open').removeClass('active');
        $('.b-header__menu').removeClass('active');
        event.stopPropagation();
    });
    $('.js-navigation[data-scroll^="#"]').on('click', function(event) {
        event.preventDefault();
    
        var navigationLink = $(this).attr("data-scroll"),
            navigationTop = $(navigationLink).offset().top - 77;
        $('html, body').animate({scrollTop: navigationTop}, 1000);
        setTimeout(function(){
            $('.js-menu-open').removeClass('active');
            $('.b-header__menu').removeClass('active'); 
        }, 1500);
    });
    function programDetailShow() {
        if ($(window).width() < 1024) {
            $('.b-program__info').on('click', function(e) {
                $(this).siblings('.b-program__detail').toggleClass('active');
            });
            $('.b-program__close').on('click', function(e) {
                $(this).parent('.b-program__detail').removeClass('active');
            });
        }
    }
    //-- перестановка блоков для карточки товара /product-new
    $(window).ready(programDetailShow());
    $(window).resize(function() {
        programDetailShow();
    });
});